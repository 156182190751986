@use "styles/loader.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media (max-width: 400px) {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .btn-container {
    position: relative !important;
    top: 2.5em !important;
    bottom: 1.5em !important;
  }
}

@media (max-width: 769px) {
  .progress-container {
    padding: 1em 2em !important;
  }
  .mat-checkbox-label {
    white-space: normal;
  }
  .mat-expansion-panel-body {
    display: flow-root;
    margin-left: 2em;
  }

  .btn-container {
    position: relative !important;
    top: 2.5em !important;
    bottom: 1.5em !important;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.btn-container {
  display: flex;
  width: 100%;
  bottom: 4em;
  justify-content: center;
}

.btn-submit {
  width: 20em;
  height: 3.5em;
  border-radius: 35px;
  background: #20623b;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: transparent;
}

.btn-disabled {
  width: 20em;
  height: 3.5em;
  border-radius: 25px;
  background: #f3f3f3;
  color: gray;
  font-size: 16px;
  font-weight: 500;
  border: transparent;
}

.back-btn-container {
  position: absolute;
  top: 2em;
  left: 3em;
  display: flex;
}

.back-written {
  font-size: 15px;
  position: relative;
  top: 3px;
  color: #20623b;
  cursor: pointer;
}

.btn-summary {
  height: 2em;
  border-radius: 15px;
  background: white;
  border: transparent;
  margin-left: 1em;
  margin-right: -7px;
}

.title-container {
  display: flex;
  justify-content: center;
  color: #20623b;
  font-weight: bold;
}

.intestazione-container {
  display: flex;
  justify-content: center;
}

.image-footer {
  max-width: 150px;
  max-height: 100px;
}

.progress-container {
  padding: 2em 4em;
}

mat-progress-bar {
  border-radius: 25px;
  height: 9px !important;
}

.mat-progress-bar-fill::after {
  background-color: #20623b;
}

.mat-progress-bar-buffer {
  background: #e4e8eb !important;
}

.btn-mobile {
  background: white;
  border: transparent;
}

mat-icon {
  color: #20623b;
  font-size: 3em;
  vertical-align: middle;
}

.mat-calendar-period-button {
  display: none !important;
}

.form-control:disabled {
  background-color: inherit;
}
