.spinner-loading {
position: fixed;
top: 0;
left: 0;
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.spinner-dot-stick,
.spinner-double-dot-stick,
.spinner-double-dot-in {
display: block;
float: left;
width: 120px;
height: 120px;
border-radius: 50%;
border: 4.125px solid rgba(50, 41, 41, 0.20);
animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
0% {
  transform: rotate(0);
}
100% {
  transform: rotate(360deg);
}
}
/* Dots */
.spinner-dot-stick,
.spinner-double-dot-stick,
.spinner-double-dot-in {
position: relative;
}
.spinner-dot-stick:before,
.spinner-double-dot-stick:before,
.spinner-dot-stick:after,
.spinner-double-dot-stick:after,
.spinner-double-dot-in:before,
.spinner-double-dot-in:after {
content: "";
position: absolute;
top: 6.25px;
left: 35.875px;
width: 12.25px;
height: 12.25px;
border-radius: 50%;
background: #20623b;
}
.spinner-dot-stick:after,
.spinner-double-dot-stick:after,
.spinner-double-dot-in:after {
top: auto;
bottom: 6.25px;
}

.spinner-dot-stick:before,
.spinner-dot-stick:after,
.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
top: -6.25px;
}
.spinner-dot-stick:after,
.spinner-double-dot-stick:after {
top: auto;
bottom: -6.25px;
}

.spinner-dot-stick:after {
display: none;
}